.listControls {
  width: 100%;
  margin: 0.4rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 1.2rem 4.8rem;
  height: calc(100% - 8.2rem);

  .button {
    width: 33%;

    color: var(--secondary-color);
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }

  .tableWrapper {
    display: flex;
    border-radius: 2px;
    background-color: var(--white);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        th {
          border-bottom: 1px solid var(--gray-middle);
          height: 4rem;
        }
      }
      tbody {
        tr:nth-child(2) {
          background-color: var(--gray-light);
        }
        td {
          height: 4rem;
          text-align: center;
        }
      }

      .actions {
        button {
          background-color: var(--primary-color);
          border-radius: 2px;
          border: none;
          color: var(--white);
          font-weight: 600;
          padding: 0.4rem 0.8rem;

          transition: all var(--fast);
          &:hover {
            background-color: var(--secondary-color);
          }
        }
      }
    }
  }
}
