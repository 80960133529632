html,
body,
div#root {
  height: 100%;

  margin: 0;
  padding: 0;

  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: "Open Sans", "Segoe UI", sans-serif;
}

button:hover {
  cursor: pointer;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
  margin-bottom: 0.6rem;
}

@media only screen and (min-width: 600px) {
  .thin-scrollbar::-webkit-scrollbar {
    width: 0.8rem;
  }
  .thin-scrollbar::-webkit-scrollbar-track {
    background-color: var(--gray-middle-lighter);
  }
  .thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--gray-middle);
  }
  .thin-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--gray-middle-dark);
    cursor: pointer;
  }
}

:root {
  font-size: 62.5% !important;

  --primary-color: #193153;
  --secondary-color: #e87308;
  --success: #065e28;
  --warn: #ca9a14;
  --error: #66081f;
  --red: #811d2e;
  --info: #0f356e;
  --white: #fff;
  --gray-lighter: #f6f6f6;
  --gray-light: #dedede;
  --gray-middle-lighter: #ccc;
  --gray-middle-light: #aaa;
  --gray-middle: #888;
  --gray-middle-dark: #555;
  --gray-dark: #333;
  --gray-darker: #222;
  --black: #000;

  --fast: 125ms;
  --slow: 2s;
}
