.wrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--gray-light);

  h2 {
    margin: 0;
    margin-bottom: 1.2rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100% - 3.2rem);

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    ol.formNavigation {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: sticky;
      top: 1rem;
      width: 20rem;
      height: 100%;
      margin-right: 6rem;
      background-color: var(--white);

      li {
        height: 6rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--gray-light);
        color: var(--primary-color);
        &.selected {
          color: var(--secondary-color);
          button {
            border-left: 4px solid var(--secondary-color);
          }
        }
        &:hover {
          color: var(--secondary-color);
        }
        &:focus-within {
          outline: auto;
        }

        button {
          height: 100%;
          width: 100%;
          background: none;
          border: none;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: 600;
          color: inherit;
          &:focus {
            outline: none;
          }
        }
      }
    }

    .formWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      
      .editingWrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.6rem;
        button {
          width: 8rem;
        }
      }

      form {
        flex-grow: 10;
        .formPage {	
          display: grid;
          grid-template-columns: auto auto auto;
          align-items: stretch;
        }
      }

      div.controls {
        flex-grow: 1;
        position: sticky;
        top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        height: fit-content;
        align-self: end;
        width: fit-content;

        button {
          height: 4rem;
          padding: 0.8rem 1.6rem;
          margin-left: 1.6rem;
        }

        :first-child {
          margin-left: 0rem;
        }

        @media only screen and (max-width: 600px) {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-left: 0;

          button {
            margin-top: 0;
          }
        }
      }
    }
  }

  .preview {
    width: calc((100% - 15%) / 2);
    min-width: 50rem;
    max-width: 80rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: gray;
  }

  &.multipleChildren {
    justify-content: space-between;
    .formWrapper {
      width: 60%;
    }
  }
}
