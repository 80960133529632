.wrapper {
  display: flex;
  flex-direction: column;

  border-radius: 2px;
  padding: 1.6rem 2rem 1.6rem 2rem;

  color: var(--gray-middle-dark);
  background-color: var(--white);
  margin-top: 3rem;
  max-width: 20rem;
  overflow: auto;

  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.15);

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1rem;

    &.success {
      color: var(--success);
    }
    &.warn {
      color: var(--warn);
    }
    &.info {
      color: var(--info);
    }
    &.error {
      color: var(--error);
    }

    h3 {
      margin: 0;
      margin-right: 2rem;
    }

    button.close {
      height: 2.4rem;
      width: 2.4rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin: 0;
      padding: 0;
      border: 0;
      background: transparent;

      > div {
        height: 2.4rem;
        width: 2.4rem;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        svg {
          path {
            fill: var(--gray-middle-dark);
          }
        }
      }
    }
  }
}
