.inputContainer {
  display: flex;
  flex-direction: column;

  padding: 0.4rem;
  border-radius: 0.2rem;
  border: none;

  background-color: var(--white);
  color: var(--gray-middle-dark);

  font-size: 1.4rem;

  .label {
    margin-bottom: 0.4rem;

    label {
      display: inline-block;
    }
  }

  input {
    min-height: 2rem;

    font-size: 1.4rem;

    padding: 0.2rem;
    border: none;
    //border-bottom: 1px solid var(--gray-middle-light);

    color: var(--gray-darker);

    &:focus {
      outline: none;
    }
  }
}
.errorText {
  font-size: small;
  color: red;
  font-style: italic;
  align-self: center;
}
