.paginationControls {
  width: fit-content;
  margin: 0.4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  transition: all var(--fast);

  .prev,
  .next,
  .pageButton,
  .filler {
    width: 3.2rem;
    height: 2.4rem;
    border: 0;
    border-radius: 2px;
    margin: 0 0.8rem;
    line-height: 1.4rem;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
  }

  .prev,
  .next,
  .pageButton {
    box-shadow: 0 0 0.1rem 0 #00000020;
    background-color: var(--white);
    &:hover:not(:disabled) {
      background-color: var(--secondary-color);
      color: var(--white);
      > div {
        svg {
          path {
            fill: var(--white);
          }
        }
      }
    }

    &:disabled {
      cursor: initial;
    }
  }

  .prev {
    margin-left: 0;
  }

  .next {
    margin-right: 0;
  }

  .prev,
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.4rem;
      width: 2.4rem;
      svg {
        height: 2.4rem;
        width: 2.4rem;
        path {
          fill: var(--black);
        }
      }
    }

    &:disabled {
      > div {
        svg {
          path {
            fill: var(--gray-middle-lighter);
          }
        }
      }
    }
  }

  .pageButton.selected {
    background-color: var(--primary-color);
    color: var(--white);
    &:hover:not(:disabled) {
      background-color: var(--primary-color);
    }
  }
}

@media only screen and (max-width: 560px) {
  .filler {
    display: none;
  }
}
