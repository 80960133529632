.rowControl{
    width: 100%;
}

button {
  padding: 0.1rem;
  border: none;
  border-radius: 2px;
  background-color: transparent;

  color: var(--gray-middle);
  font-weight: 700;

  &.deleteButton {
    svg {
      path {
        fill: var(--red);
        transition: all var(--fast);
      }
    }
    &:hover {
      background-color: var(--red);
      transition: all var(--fast);
      svg {
        path {
          fill: var(--white);
          transition: all var(--fast);
        }
      }
    }
  }

  &.addButton {
    svg {
      path {
        fill: var(--secondary-color);
        transition: all var(--fast);
      }
    }
    &:hover {
      background-color: var(--secondary-color);
      transition: all var(--fast);
      svg {
        path {
          fill: var(--white);
          transition: all var(--fast);
        }
      }
    }
  }
}

.questions {
    max-height: 40rem;
    overflow-y: auto;
}

.footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1.2rem;
  
    button {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
}