.listControls {
    width: 100%;
    margin: 0.4rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .newButtonWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  
    margin-bottom: 1.2rem;
  
    a {
      text-decoration: none;
      button {
        width: 8rem;
      }
    }
  }
  
  .wrapper {
    padding: 0 1.6rem 1.6rem 1.6rem;
    background-color: var(--white);
    border-radius: 2px;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  
    .transfersListSection {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
  
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          th {
            text-align: left;
            padding: 1rem 0;
          }
        }
  
        th,
        td {
          &:last-child {
            width: 15rem;
          }
        }
  
        tbody {
          tr {
            border-top: 1px solid var(--gray-light);
            &:last-child {
              border-bottom: 1px solid var(--gray-light);
            }
          }
  
          td {
            padding: 1rem 0;
            .transferOptions {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              button {
                min-width: 8rem;
                height: 3.2rem;
                border: none;
                border-radius: 2px;
                background-color: transparent;
                color: var(--gray-middle);
                font-weight: 700;
                &:hover {
                  background-color: var(--primary-color);
                  color: var(--white);
                  transition: all var(--fast);
                }
              }
            }
          }
        }
      }
    }
  }
  