.overlay {
  height: 100%;
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.2);

  &.hidden {
    display: none;
  }

  .modal {
    width: 40%;
    min-width: 40rem;
    max-width: 60rem;
    max-height: calc(100% - 10rem);

    display: flex;
    flex-direction: column;

    padding: 2.4rem;
    border-radius: 2px;

    background-color: var(--gray-lighter);

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 1.6rem;

      h3 {
        margin: 0;
      }

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        border: 0;
        padding: 0;
        margin: 0;

        background-color: transparent;

        > div {
          height: 16px;
          width: 16px;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          svg {
            fill: var(--gray-dark);
            path {
              fill: var(--gray-dark);
            }
          }
        }
      }
    }
  }
}
