.PopoverOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    gap: 0.5rem;
    padding: 0.6rem;
    border: 1px solid var(--gray-middle-lighter);
    background-color: var(--gray-lighter);
    padding: 0.3rem 0.1rem 0.3rem 0.1rem;
    a {
      width: 100%;
      height: 3.2rem;
    }
    button {
        padding: 0.6rem;
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 2px;
        background-color: transparent;
        color: var(--gray-middle);
        font-weight: 700;
        &:hover,
        &:focus {
          background-color: var(--primary-color);
          color: var(--white);
          transition: all var(--fast);
          outline-offset: 2px;
        }
    }
}