
.TooltipPopover {
  gap: 0.5rem;
  padding: 0.6rem;
  border-radius: 2px;
  background-color: var(--white);
  box-shadow: 0 0 7px 0 #0000003A;
  white-space: pre-line;
}

.ArrowContainer{
  max-width: 48rem;
}