.listWrapper {
  position: sticky;
  top: 4.8rem;

  background-color: var(--white);
  border-radius: 2px;

  width: 36rem;

  display: flex;
  flex-direction: column;

  padding: 0 1.6rem;

  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    border-bottom: 1px solid var(--gray-middle-lighter);
  }

  ol.notificationList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    min-height: 40rem;
    max-height: 76rem;
    overflow-y: auto;

    li.notification {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 5rem;
      border-bottom: 1px solid var(--gray-middle-lighter);

      div.notificationIcon {
        height: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > svg {
          width: 2rem;
          path {
            fill: var(--secondary-color);
          }
        }
      }

      div.anchorIcon {
        height: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > svg {
          width: 2rem;
          path {
            fill: var(--gray-middle-lighter);
            transition: all var(--fast) ease;
          }
        }

        &:hover {
          cursor: pointer;
          > svg {
            path {
              fill: var(--secondary-color);
              transition: all var(--fast) ease;
            }
          }
        }
      }

      > div {
        p {
          margin: 0;
        }

        &:nth-child(1) {
          width: calc(100% / 12);
        }
        &:nth-child(2) {
          width: calc(10 * 100% / 12);
          padding: 0 1.6rem;
        }
        &:nth-child(3) {
          width: calc(100% / 12);
        }
      }
    }
  }
}

@media only screen and (max-width: 1120px) {
  .listWrapper {
    position: static;
  }
}
