.wrapper {
  margin: 0 4.8rem;

  .pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 6.4rem;

    h2 {
      margin: 0;
    }

    button {
      width: 8rem;
    }

    a {
      text-decoration: none;
    }
  }
}
