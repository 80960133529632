.wrapper {
  height: 100%;

  position: fixed;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  z-index: 1;

  .navbarIcons {
    width: 4.8rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    color: var(--white);
    background-color: var(--primary-color);

    ul {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    li {
      width: 100%;
      height: 4.8rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      position: relative;

      &.active {
        background-color: var(--secondary-color);
        transition: background-color var(--fast) ease;
      }
      transition: background-color var(--fast) ease;

      img {
        width: 3.6rem;
      }

      .selectedMarker {
        height: 100%;
        width: 0;

        position: absolute;
        left: 0;

        &.active {
          width: 3px;
          background-color: var(--white);
          transition: width var(--fast) ease, height var(--fast) ease;
        }
        transition: width var(--fast) ease, height var(--fast) ease;
      }

      a {
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > div {
          height: 24px;
        }
      }
    }
  }

  .navbar {
    width: 0;
    height: 100%;

    

    box-shadow: 0 0 10 10 rgba(0, 0, 0, 0.15);
    border-right: 1px solid var(--gray-middle-lighter);

    background-color: var(--gray-lighter);

    overflow: hidden;

    &.active {
      width: 24rem;
      transition: width var(--fast) ease;
    }
    transition: width var(--fast) ease;

    ul {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      li {
        width: 100%;
        height: 4.8rem;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        color: var(--primary-color);

        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.6rem;

        a {
          width: 100%;
          height: 100%;

          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          margin: 0 0.8rem;
          border-top: 1px solid rgba(0, 0, 0, 0.05);

          color: var(--primary-color);

          
          text-decoration: none;
        }

        &:last-child a {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        &.active {
          background-color: var(--white);
          transition: background-color var(--fast) ease;
        }
        transition: background-color var(--fast) ease;
      }
    }
  }
}
