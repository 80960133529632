.inputContainer {
  display: flex;
  flex-direction: column;

  padding: 0.4rem;
  border-radius: 0.2rem;
  border: 1px solid var(--gray-middle-lighter);

  background-color: var(--white);
  color: var(--gray-middle-dark);

  font-size: 1.4rem;

  .label {
    margin-bottom: 0.4rem;

    label {
      display: inline-block;
    }
  }

  &:focus-within {
    outline: auto;
  }

  textarea {
    min-height: 5rem;
    resize: vertical;

    font-family: Calibri, "Trebuchet MS", sans-serif;
    font-size: 1.4rem;

    padding: 0.2rem;
    border: none;

    color: var(--gray-darker);

    &:focus {
      outline: none;
    }
  }
}
