.inputContainer {
  display: flex;
  flex-direction: column;

  padding: 0.4rem;
  border-radius: 0.2rem;
  border: 1px solid var(--gray-middle-lighter);

  background-color: var(--white);
  color: var(--gray-middle-dark);

  font-size: 1.4rem;

  .label {
    margin-bottom: 0.4rem;

    label {
      display: inline-block;
    }
  }

  .innerFileInput {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .fileTable {
    width: 100%;
    border-collapse: collapse;

    tbody {
      tr {
        td {
          border-top: 1px solid var(--gray-middle-lighter);
        }
      }
    }

    td,
    th {
      text-align: left;
      padding: 0.4rem;

      &:first-child {
        width: 10rem;
      }

      &:last-child {
        width: 10%;
      }
    }

    .imagePreview {
      max-width: 10rem;
      max-height: 10rem;
    }

    button {
      border: 0;
      border-radius: 2px;
      padding: 0.5rem;
      width: 8rem;

      font-weight: bold;

      background-color: transparent;

      &.selectFileButton {
        color: var(--secondary-color);

        &:hover,
        &:focus {
          background-color: var(--secondary-color);
          color: var(--white);
          
          path {
            fill: var(--white);
          }
        }

        display: flex;
        gap: 1rem;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        path {
          fill: var(--secondary-color);
        }

        > div {
          svg {
            height: 1.4rem;
            width: 1.4rem;
          }
        }
      }

      &.removeButton {
        color: var(--red);

        &:hover,
        &:focus {
          background-color: var(--red);
          color: var(--white);
        }
      }

      transition: all var(--fast) linear;
    }
  }
}

.downloadFileButton {
  color: var(--secondary-color);

  &:hover,
  &:focus {
    background-color: var(--secondary-color) !important;
    color: var(--white);
    
    path {
      fill: var(--white);
    }
  }

  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  path {
    fill: var(--secondary-color);
  }

  > div {
    padding: 0;
    height: 2rem;
    width: 2rem;
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}

.fileAnchor {
  text-decoration: unset;
}

.fileActions {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}