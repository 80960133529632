.statusLabel {
  margin-top: 1.2rem;
  .statusTag {
    margin-left: 0.8rem;
  }
}

.statusTag {
  font-weight: 600;
  color: var(--white);
  padding: 0.4rem 0.8rem;
  border-radius: 0.2rem;
  width: fit-content;
  height: min-content;
  text-align: center;
}

.incomplete {
  background-color: var(--error);
}

.outdated {
  background-color: var(--error);
}

.pending {
  background-color: var(--info);
}

.editable {
  background-color: var(--success);
}

.approved {
  background-color: var(--success);
}

.blank {
  background-color: var(--gray-middle);
}
