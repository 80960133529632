.formPageWrapper {
  .editingWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
    button {
      width: 8rem;
    }
  }
}

.downloadButton {
  position: fixed;
  bottom: 3.2rem;
  right: 6.4rem;
  width: 8rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 0.3rem;
  border: none;
  background-color: var(--primary-color);
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--white);

  &:hover {
    background-color: var(--secondary-color);
  }

  transition: all var(--fast);

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    margin-top: 1rem;
    svg {
      height: 2rem;
      width: 2rem;
      path {
        fill: var(--white);
      }
    }
  }
}

.downloadButtonText {
  color: var(--white);
  text-decoration: none;
}