.wrapper {
  padding: 0 1.6rem 1.6rem 1.6rem;
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  .projectFinanceSection{
    table {
      width: 100%;
      border-collapse: collapse;
      thead{  
        background-color: var(--white);
        color: var(--secondary-color);
        border-bottom: 3px solid var(--secondary-color);
        th{
          text-align: center;
          font-size: 22px;
          padding: 0.8rem;
        }
      }
      tbody{
        tr:nth-child(even) {background-color: #f2f2f2;}
        th{
          text-align: left;
          font-size: 18px;
          font-weight: 500;
          padding: 1rem;
        }
        td{
          text-align: right;
          font-size: 18px;
          padding: 1rem;
        }
      }
    }
  }
  .projectTransferSection{
    table {
      width: 100%;
      border-collapse: collapse;
      thead{  
        background-color: var(--white);
        color: var(--secondary-color);
        border-bottom: 3px solid var(--secondary-color);
        th{
          text-align: center;
          font-size: 22px;
          padding: 0.8rem;
        }
      }
      tbody{
        tr:nth-child(even) {background-color: #f2f2f2;}
        th{
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          padding: 1rem;
      }
        td{
          text-align: center;
          font-size: 18px;
          padding: 1rem;
        }
      }
    }
  }
}
