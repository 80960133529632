.wrapper {
  display: flex;
  flex-direction: column;
  margin: 1.2rem 4.8rem;
  height: calc(100% - 8.2rem);

  .tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1.5px solid var(--gray-light);
    margin-bottom: 1rem;

    button {
      border: none;
    }

    ul {
      display: flex;
      padding: 0 0.4rem;
      max-width: 50rem;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: initial;
        height: 0.2rem;
      }
      &::-webkit-scrollbar-track {
        background: var(--gray-light);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--gray-middle-lighter);
      }
      li {
        margin: 0 0.4rem;
      }
    }
  }

  iframe {
    height: calc(100% - 2rem);
    width: 100%;
    box-shadow: 0 0 0.5rem 0 #00000020;
  }
}

.tabSelect {
  margin-right: 1.6rem;
  > div > select {
    border: none;
    box-shadow: 0 0 0.2rem 0 #00000020;
  }
}

.prev,
.next,
.tabButton {
  height: 2.4rem;
  border: 0;
  border-radius: 2px;
  line-height: 1.4rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.prev,
.next,
.tabButton {
  box-shadow: 0 0 0.2rem 0 #00000020;
  background-color: var(--white);
  &:hover:not(:disabled) {
    background-color: var(--secondary-color);
    color: var(--white);
    > div {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }

  &:disabled {
    cursor: initial;
  }
}

.prev {
  margin-left: 0;
}

.next {
  margin-right: 0;
}

.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  margin: 0 0.8rem;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.4rem;
    width: 2.4rem;
    svg {
      height: 2.4rem;
      width: 2.4rem;
      path {
        fill: var(--black);
      }
    }
  }

  &:disabled {
    > div {
      svg {
        path {
          fill: var(--gray-middle-lighter);
        }
      }
    }
  }
}

.tabButton {
  width: max-content;
}

.tabButton.selected {
  background-color: var(--primary-color);
  color: var(--white);
  &:hover:not(:disabled) {
    background-color: var(--primary-color);
  }
}
