.wrapper {
  .tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.2rem;

    a {
      margin-right: 1.2rem;
      button {
        padding: 0.5rem 1.2rem;
        border: 0;
        border-radius: 2px;

        font-weight: 600;

        color: var(--white);
        background-color: var(--primary-color);

        &.selected {
          background-color: var(--secondary-color);
        }
        &:focus {
          outline-offset: 2px;
        }
      }
    }
  }
  .statusWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    svg {
      height: 2rem;
      width: 2rem;
      margin-right: 0.3rem;
    }
  }

  .validated, .notValidated {
    margin-bottom: 1.2rem;
    font-weight: 600;
  }

  .validated {
    color: var(--success);
  }

  .notValidated {
    color: var(--error)
  }

  .formPageWrapper {
    > div {
      margin: 0;
      > div > div {
        padding: 0 !important;
      }
    }
    .score {
      width: max-content;
      padding: 0.5rem 1.2rem;
      color: black;
      background-color: white;
      border-radius: 2px;
      font-weight: 600;
      border: 2.5px solid var(--gray-middle-lighter);
    }
  }
  .editingWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
    button {
      width: 8rem;
    }
  }
}
