.listControls {
  width: 100%;
  margin: 0.4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper {
  padding: 0 1.6rem 1.6rem 1.6rem;
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);

  .projectListSection {
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
          padding: 1rem;
        }
      }

      tbody {
        tr {
          border-top: 1px solid var(--gray-middle-lighter);
          &:last-child {
            border-bottom: 1px solid var(--gray-middle-lighter);
          }
        }

        td {
          padding: 1rem;

          .projectOptions {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            button {
              margin-left: 0.6rem;
              padding: 0.6rem;
              height: 3.2rem;
              border: none;
              border-radius: 2px;
              background-color: transparent;
              color: var(--gray-middle);
              font-weight: 700;
              &:hover,
              &:focus {
                background-color: var(--primary-color);
                color: var(--white);
                transition: all var(--fast);
                outline-offset: 2px;
              }
            }
          }
        }
      }
    }
  }
}
