.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.2rem;
}

.wrapper {
  padding: 0 4.8rem;
  margin-top: 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .leftColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    .greetings {
      width: 100%;
      margin-bottom: 2rem;
      font-size: 2rem;
    }
  }

  .rightColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 4.8rem;
  }
}

@media only screen and (max-width: 1120px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
