.wrapper {
  width: 14rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0.6rem 0;

  .notifications {
    height: 2rem;
    width: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 1rem;

    font-weight: 800;
    line-height: 1rem;

    color: var(--white);
    background-color: var(--secondary-color);
  }

  .profilePic {
    height: 3.2rem;
    width: 3.2rem;

    border: 0.2rem solid var(--white);
    border-radius: 1.8rem;

    background-color: var(--primary-color);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .username {
    font-size: 1.4rem;
    line-height: 1.4rem;

    color: var(--gray-dark);
  }

  .logoutMenu {
    display: none;
  }

  &:hover {
    .logoutMenu {
      display: block;
      position: absolute;
      top: 4.8rem;
      width: 16rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      ol {
        width: 100%;
        border-radius: 0.2rem;
        border: 1px solid var(--gray-lighter);
        box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.1);
        li {
          width: 100%;
          height: 3.6rem;
          a {
            width: 100%;
            height: 100%;
            button {
              width: 100%;
              height: 100%;
              padding: 0.8rem;
              color: var(--primary-color);
              background-color: var(--white);
              border: 0;
              line-height: 1.8rem;
              font-size: 1.6rem;
              font-weight: bold;
              &:hover {
                color: var(--secondary-color);
              }
            }
          }
        }
      }
    }
  }
}
