.inputContainer {
  display: flex;
  flex-direction: column;

  padding: 0.4rem;
  border-radius: 0.2rem;

  background-color: var(--white);
  color: var(--gray-middle-dark);

  font-size: 1.4rem;

  .label {
    margin-bottom: 0.4rem;

    label {
      display: inline-block;
    }
  }

  input[type="checkbox"] {
    display: none;
  }
}

.inputLabelWrapper {
  label {
    display: flex;
    align-items: center;
    width: 11rem;

    .switchWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      background: transparent;
      border: 0;
      padding: 0.6rem;
      span {
        margin: 0;
      }
    }

    .switchTrack {
      height: 2rem;
      width: 4rem;
      border-radius: 1rem;
      background-color: var(--gray-middle-light);
      transition: background-color var(--fast) ease-in-out;

      .switchThumb {
        height: 1.4rem;
        width: 1.4rem;
        border-radius: 0.7rem;
        background-color: var(--white);
        position: relative;
        top: 0.3rem;
        left: 0.3rem;
        transition: left var(--fast) ease-in-out;
      }

      &.active {
        background-color: var(--secondary-color);
        .switchThumb {
          left: calc(4rem - 0.3rem - 1.4rem);
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
