.layout {
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--gray-lighter);
}

.contentWrapper {
  width: 100%;
  height: 100%;
  margin-left: 4.8rem;

  overflow: auto;
}

.toastContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  height: fit-content;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 2rem 4rem;
  width: fit-content;
}
