.wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  form.signup {
    width: 36rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 1.6rem;
    border-radius: 2px;

    background-color: var(--white);

    & > div[class*="inputContainer"] {
      width: 100%;
      margin-bottom: 1rem;
    }

    button {
      width: 33%;
    }

    button.signup {
      width: 100%;
    }

    .useragreement {
      font-style: italic;
      font-size: small;
    }
  }
}
