.wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  form.signup {
    width: 36rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 1.6rem;
    border-radius: 2px;

    background-color: var(--white);

    & > div[class*="inputContainer"] {
      width: 100%;
      margin-bottom: 1rem;
    }

    button {
      width: 33%;
    }

    button.signup {
      width: 100%;
    }

    button.terms{
      display: inline-block;
      border: 0;
      background-color: transparent;
      font-size: small;
      width: initial;
      text-transform: initial;
      font-style: italic;
      text-decoration: underline solid black 1px;
      &:hover {
        text-decoration-color: underline solid var(--secondary-color) 1px; 
        color: var(--secondary-color)
      }
    }

    .useragreement {
      font-style: italic;
      font-size: small;

      > button {
        margin-left: 0.2rem;
      }
    }
  }
}

.userAgreementModal {

  .checkMark {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
    gap: 1rem;

    svg {
      path {
        fill: var(--secondary-color);
      }
    }
  }
}
