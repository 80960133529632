.filtersWrapper {
  width: fit-content;
  margin: 0.4rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    li {
      background-color: var(--white);
      color: var(--secondary-color);
      width: fit-content;
      padding: 0.4rem 0.8rem;
      margin: 0rem;
      border-radius: 0.2rem;
      font-weight: 600;

      &:not(:last-of-type) {
        margin-right: 0.8rem;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > button {
        border: none;
        background-color: transparent;
        margin: 0;
        padding: 0;
        margin-left: 0.8rem;
        box-shadow: 0 0 0.1rem 0 #00000020;
        > div {
          height: 1rem;
          width: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            height: 1rem;
            width: 1rem;
            path {
              fill: var(--error);
            }
          }
        }
      }
    }
  }
}

.filterControls {
  margin: 0 0.8rem;
}

.filterControls,
.filterModalControls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0.4rem 0.8rem;
    margin: 0rem;
    border: none;
    border-radius: 0.2rem;
    font-weight: 600;
    background-color: var(--primary-color);
    color: white;
    transition: all var(--fast);

    &:not(:last-of-type) {
      margin-right: 0.8rem;
    }

    &:hover {
      background-color: var(--secondary-color);
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
      width: 2rem;
      margin-left: 0.8rem;
      svg {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}
