.formPageWrapper {
  .editingWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
    button {
      width: 8rem;
    }
  }
}
