.inputContainer {
  li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.6rem 0;

    input {
      opacity: 0;
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
      display: inline-box;
    }

    label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.6rem;

        svg {
          path {
            fill: var(--gray-darker);
          }
        }
      }

      &.selected {
        > div {
          svg {
            path {
              fill: var(--secondary-color);
              transition: all var(--fast);
            }
          }
        }
      }

      &.inactive {
        > div {
          svg {
            path {
              fill: var(--gray-middle);
              transition: all var(--fast);
            }
          }
        }
      }
    }

    &:focus-within {
      outline: auto;
    }
  }
}
