.roadmapPanel {
  h3 {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1.2rem;
  }
  padding: 1.6rem;
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
  width: 36rem;
  margin-bottom: 4.8rem;
}

.pendingSectionsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  li {
    width: calc(100% - 8rem);
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      border-top: 1px solid var(--gray-light);
    }

    span.sectionName {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 16rem;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      line-height: 1.4rem;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.4rem;
        svg {
          width: 1.4rem;
          height: 1.4rem;
          path {
            fill: var(--gray-middle);
          }
        }
      }

      &:hover {
        color: var(--secondary-color);
        > div {
          svg {
            path {
              fill: var(--secondary-color);
            }
          }
        }
      }
    }

    span:nth-child(2) {
      padding: 0.2rem 0.6rem;
      font-size: 1.4rem;
      width: 8rem;
      text-align: center;
    }
  }
}
