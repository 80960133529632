.wrapper {
  background-color: var(--white);

  &.fitTable {
    width: calc(100% - 2.4rem);
  }

  padding: 0.8rem;
  margin: 0.8rem;
  border-radius: 2px;

  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    h3 {
      margin: 0;
      margin-bottom: 0.8rem;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }
}

.helpIcon {
  button {
    background: transparent;
    border: none;
    height: 2.4rem;
    width: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 2rem;
      width: 2rem;
      path {
        fill: var(--secondary-color);
      }
    }
  }
}
