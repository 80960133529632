button.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 1.6rem;
  font-weight: 600;

  border-radius: 0.3rem;
  padding: 0.8rem 0;

  border-style: solid;
  border-width: 0.15rem;

  &.filled:not(:disabled) {
    color: var(--white) !important;
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    transition: all var(--fast) ease;

    &:hover {
      cursor: pointer;
      background-color: var(--white);
      color: var(--secondary-color) !important;
      border-color: var(--white);
      box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
      transition: all var(--fast) ease;
    }
  }

  &.outlined:not(:disabled) {
    border-color: var(--secondary-color);

    &:not(:hover) {
      color: var(--secondary-color);
      background-color: white !important;
      transition: all var(--fast) ease;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--secondary-color);
      color: var(--white);
      transition: all var(--fast) ease;
    }
  }

  &:disabled {
    color: var(--gray-middle-light);
    border-color: var(--gray-middle-light);
    &:hover {
      cursor: not-allowed;
    }
  }
}

button {
  &:hover {
    path {
      fill: white;
      fill-opacity: 1;
    }
  }
}