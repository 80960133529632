.wrapper {
  width: 100%;
  .tableWrapper {
    overflow-x: auto;
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid var(--gray-light);
        td,
        th {
          padding: 1rem;
          text-align: left;
          word-wrap: break-word;
          text-overflow: ellipsis;
          min-width: 10rem;
          max-width: 30rem;

          .booleanTrueIcon {
            svg {
              path {
                fill: var(--success);
              }
            }
          }

          .booleanFalseIcon {
            svg {
              path {
                fill: vaR(--error);
              }
            }
          }

          &.rowControls {
            min-width: unset;
            width: 4.8rem;

            div {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              flex-wrap: wrap;
              button {
                width: 2.4rem;
                height: 2.4rem;
                padding: 0.1rem;
                border: none;
                border-radius: 2px;
                background-color: transparent;

                color: var(--gray-middle);
                font-weight: 700;

                div {
                  height: 100%;
                  width: 100%;
                  svg {
                    height: 100%;
                    width: 100%;
                    path {
                      fill: var(--primary-color);
                      transition: all var(--fast);
                    }
                  }
                }

                &:hover {
                  background-color: var(--primary-color);
                  color: var(--white);

                  svg {
                    path {
                      fill: var(--white);
                      transition: all var(--fast);
                    }
                  }

                  transition: all var(--fast);
                }

                &.deleteButton {
                  svg {
                    path {
                      fill: var(--red);
                      transition: all var(--fast);
                    }
                  }
                  &:hover {
                    background-color: var(--red);
                    transition: all var(--fast);
                    svg {
                      path {
                        fill: var(--white);
                        transition: all var(--fast);
                      }
                    }
                  }
                }

                &.addButton {
                  svg {
                    path {
                      fill: var(--secondary-color);
                      transition: all var(--fast);
                    }
                  }
                  &:hover {
                    background-color: var(--secondary-color);
                    transition: all var(--fast);
                    svg {
                      path {
                        fill: var(--white);
                        transition: all var(--fast);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.imagePreviewWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .previewAndName {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    > div {
      display: inline-block;
      > svg {
        path {
          fill: var(--gray-middle-light);
        }
      }
    }
  }

  .imagePreview {
    max-width: 10rem;
    max-height: 10rem;
  }


  label {
    margin-left: 0.4rem;
  }
}
