.questions {
  max-height: 40rem;
  overflow-y: auto;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1.2rem;

  button {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
