.wrapper {
  width: 100%;
  .tableWrapper {
    overflow-x: auto;
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid var(--gray-light);
        td,
        th {
          padding: 1rem;
          text-align: left;
          word-wrap: break-word;
          text-overflow: ellipsis;
          min-width: 10rem;
          max-width: 30rem;

          .booleanTrueIcon {
            svg {
              path {
                fill: var(--success);
              }
            }
          }

          .booleanFalseIcon {
            svg {
              path {
                fill: var(--error);
              }
            }
          }
        }
      }
    }
  }
}

