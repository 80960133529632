.wrapper {
  margin-bottom: 16rem;
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      width: 8rem;
    }
  }

  .editingWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
    button {
      width: 8rem;
    }
  }
}
