.inputContainer {
  display: flex;
  flex-direction: column;

  background-color: var(--white);
  color: var(--gray-middle-dark);

  font-size: 1.4rem;

  .label {
    margin-bottom: 0.4rem;

    label {
      display: inline-block;
    }
  }

  &:focus-within {
    outline: auto;
  }

  select {
    min-height: 2rem;

    font-size: 1.4rem;

    padding: 0.6rem;
    border-radius: 0.2rem;
    border: 1px solid var(--gray-light);

    &:focus {
      outline: none;
    }
  }
}
