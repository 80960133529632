.wrapper {
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loadingWheel {
    border-radius: 3rem;
    height: 3rem;
    width: 3rem;
    border: 0.3rem solid var(--white);
    background-color: var(--gray-lighter);
    box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.1);
    animation: spinning calc(var(--slow) / 2) infinite linear,
      colorShift calc(2 * var(--slow)) infinite linear;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes colorShift {
  0% {
    border-top-color: var(--secondary-color);
  }

  40% {
    border-top-color: var(--secondary-color);
  }

  50% {
    border-top-color: var(--primary-color);
  }

  90% {
    border-top-color: var(--primary-color);
  }

  100% {
    border-top-color: var(--secondary-color);
  }
}
