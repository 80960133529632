.wrapper {
  display: flex;
  flex-direction: row;

  padding: 0 4.8rem;

  background-color: inherit;

  .content {
    width: 100%;
    min-height: 4.8rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--gray-middle-lighter);

    > p {
      color: var(--primary-color);

      margin: 0;

      font-size: 2rem;
      font-weight: 500;
      line-height: 2rem;
      text-transform: uppercase;
    }

    > a {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;

      font-weight: 600;
      text-decoration: none;

      > div {
        height: 2.4rem;
        width: 2.4rem;

        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;

        margin-right: 1.6rem;
        svg {
          path {
            fill: var(--primary-color);
            transition: fill var(--fast) ease;
          }
        }
      }

      &.active,
      &:hover {
        color: var(--secondary-color);
        > div {
          svg {
            path {
              fill: var(--secondary-color);
              transition: fill var(--fast) ease;
            }
          }
        }
        transition: color var(--fast) ease;
      }
      transition: color var(--fast) ease;
    }
  }
}
